#creds {
  display: none;
  text-align: center;
}

#viewer {
  border: none;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.iframe-container {
  position: relative;
  height: calc(100vh - 38px);
  overflow: hidden;
}

.logout, .user {
  float: right;
  margin-right: 6px;
}

.logout {
  margin-top: 6px;
}

.user {
  font-size: 14px;
  margin-top: 9px;
}

.menu {
  display: inline-flex;
}

.menu-item {
  margin: 6px;
}
.tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px;
  border: 1px solid black;
}